<template>
  <div class="side-bar-content-wrap">
    <!--      側欄 header-->
    <el-menu-item>
      <img v-if="isCollapse"
           src="../../assets/image/logo/onestep-f09.png"
           alt="">
      <template #title>
        <img class="w-[150px] mt-[35px] cursor-pointer"
             :class="!isLogin?'mb-[18px]':'mb-[28px]'"
             @click="goHome(route.query)"
             src="../../assets/image/logo/logoFull.png"
             alt=""/>
        <div v-if="!isLogin"
             class="flex">
          <div
              class="mr-[14px] w-[100px] h-[36px] leading-[36px] bg-osn-white-00 text-osn-dark-grey-00 text-[15px] rounded-full text-center cursor-pointer"
              @click="handelLoginBtn">{{ $t('menu.login') }}
          </div>
          <div
              class="w-[100px] h-[36px] leading-[36px] bg-osn-dark-grey-00 text-[#F7C767] text-[15px] rounded-full text-center cursor-pointer"
              @click="toWebsite(backstageLoginUrl)">{{ $t('menu.owner_login') }}
          </div>
        </div>
        <div v-else
             class="flex items-center">
          <div class="text-[16px]">{{ $t('menu.welcome') + name }}</div>
        </div>
      </template>
    </el-menu-item>
    <!--      側欄內容-->
    <el-menu-item @click="go('/account')">
      <img class="icon"
           src="@/assets/image/icon/menu/account_circle.svg"
           alt="">
      <template #title>{{ $t('menu.account_manager') }}</template>
    </el-menu-item>
    <el-menu-item @click="go('/order')">
      <img class="icon"
           src="@/assets/image/icon/menu/list_alt.svg"
           alt="">
      <template #title>{{ $t('menu.check_order') }}</template>
    </el-menu-item>
    <el-menu-item @click="toWebsite(lineUrl)">
      <img class="icon"
           src="@/assets/image/icon/menu/line.svg"
           alt="">
      <template #title>{{ $t('menu.line') }}</template>
    </el-menu-item>
    <el-menu-item @click="toWebsite(helpUrl)">
      <img class="icon"
           src="@/assets/image/icon/menu/info.svg"
           alt="">
      <template #title>{{ $t('menu.help_center') }}</template>
    </el-menu-item>
    <el-menu-item @click="toWebsite(termsUrl)">
      <img class="icon"
           src="@/assets/image/icon/menu/policy.svg"
           alt="">
      <template #title>{{ $t('menu.contract_terms') }}</template>
    </el-menu-item>
    <el-menu-item @click="toWebsite(privacyUrl)">
      <img class="icon"
           src="@/assets/image/icon/menu/privacy.svg"
           alt="">
      <template #title>{{ $t('menu.privacy_policy') }}</template>
    </el-menu-item>
    <el-menu-item v-if="isLogin"
                  @click="handleLogout">
      <img class="icon"
           src="@/assets/image/icon/menu/logout.svg"
           alt="">
      <template #title>{{ $t('menu.logout') }}</template>
    </el-menu-item>
    <!--      側欄 footer-->
    <div v-if="!isCollapse"
         class="side-bar-footer">
      <ul>
        <li @click="toWebsite(hostUrl)">{{ $t('menu.have_space') }}</li>
        <li class="!mb-[30px]"
            @click="toWebsite(hostUrl)">{{ $t('menu.join_us') }}
          <img src="../../assets/image/icon/svg/arrow_circle_right.svg"
               alt="">
        </li>
        <li class="!mb-0">Copyright © 2024 One Step Technology Ltd.</li>
        <li>All Rights Reserved</li>
        <li>Powered by One Step Version 1.0</li>
      </ul>
    </div>

  </div>
</template>
<script setup>
import {computed, ref} from "vue";
// import {useGoTo} from "@/composables/useGoTo";
// import {useUserStore} from "@/stores/user.js";
// import {useSettingStore} from "@/stores/setting.js";
import {useRoute} from "nuxt/app";

const props = defineProps({
  isCollapse: {
    type: Boolean,
  }
})

const userStore = useUserStore()
const settingStore = useSettingStore()
const isMobile = computed(() => settingStore.isMobile);
const isLogin = computed(() => userStore.getToken);
const name = computed(() => userStore.getName);
const {go, toWebsite,goHome} = useGoTo()
const route = useRoute();

// 外連 url
const hostUrl = ref(import.meta.env.VITE_APP_BASE_HOST_URL)
const faceBookUrl = ref(import.meta.env.VITE_APP_BASE_FACEBOOK_URL)
const lineUrl = ref(import.meta.env.VITE_APP_BASE_LINE_URL)
const helpUrl = ref(import.meta.env.VITE_APP_BASE_HELP_URL)
const termsUrl = ref(import.meta.env.VITE_APP_BASE_TERMS_URL)
const privacyUrl = ref(import.meta.env.VITE_APP_BASE_PRIVACY_URL)
const backstageLoginUrl = import.meta.env.VITE_APP_BASE_BACKSTAGE_URL+'login'

async function handleLogout() {
  await userStore.logout()
  go('/')
}

function handelLoginBtn() {
  if (isMobile.value) {
    go('/login')
  } else {
    settingStore.updateLoginDialogVisible(true)
  }
}
</script>
<style scoped>


.side-bar-content-wrap {

  & .el-menu-item {
    @apply h-[60px] flex items-center text-[16px];

    img.icon {
      @apply w-[24px] h-[24px] mr-[21px];
    }
  }


  & .side-bar-footer {
    @apply mt-auto pt-[60px] pl-[20px] border-gray-300 text-[14px];

    ul {
      @apply text-left text-[16px] text-osn-black-00;

      li {
        @apply flex mb-[12px] cursor-pointer;
      }

      li:nth-child(3), li:nth-child(4), li:nth-child(5) {
        @apply text-[12px] text-osn-dark-grey-43 cursor-default;
      }
    }
  }
}
</style>

<style>
.el-menu-item:first-child {
  @apply !p-0 cursor-default !h-[141px] flex flex-col bg-osn-yellow-00;

  & .el-menu-tooltip__trigger {
    @apply px-[14px];

    & img {
      @apply !w-[34px];
    }
  }
}

.el-menu-item:first-child:hover {
  @apply bg-osn-yellow-00
}

.el-dialog__header {
  @apply p-0;
}
</style>

