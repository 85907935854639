<template>
  <div class="select-none flex w-screen min-h-screen">
    <el-menu v-if="!isMobile&&props.sideBar"
             class="w-[300px] border-0 shadow-2xl">
      <CollapseSideBarContent></CollapseSideBarContent>
    </el-menu>
    <div class="flex flex-col flex-grow">
      <div v-show="props.navBar"
           class='w-full hidden md:flex md:h-[80px] bg-osn-yellow-00 justify-around items-center'>
        <el-icon v-if="props.back"
                 :size="28"
                 @click="goHome(route.query)"
                 class="cursor-pointer">
          <ArrowLeftBold/>
        </el-icon>
        <img src="../assets/image/logo/logoFull.png"
             class=" w-[140px] h-[35px]"
             alt="">
        <div v-if="props.back" class="w-[28px]"></div>
      </div>
      <div v-if="!isMobile&&props.returnToIndex"
           class="absolute top-[23px] right-[50px] w-[116px] h-[34px] bg-osn-dark-grey-00 text-osn-white-00
           inline-flex items-center justify-center rounded-[17px] text-[14px] mx-auto cursor-pointer"
           @click="goHome(route.query)">
        <img src="@/assets/image/icon/menu/distance.svg"
             alt="">{{ $t('menu.return_to_index') }}
      </div>
      <div class="flex-grow overflow-y-auto">
        <div class="flex">
          <div class="flex-auto">
            <slot></slot>
          </div>
        </div>
      </div>
      <div v-if="props.footer"
           class="hidden md:block">
        <Footer></Footer>
      </div>
    </div>
  </div>
</template>
<script setup>
import {computed, ref,inject, reactive} from "vue";
import CollapseSideBarContent from "~/components/MenuNav/CollapseSideBarContent.vue";
// import {useRoute, useRouter} from "vue-router";
import {ArrowLeftBold} from "@element-plus/icons-vue";

import {useRoute} from "nuxt/app";
// import {useGoTo} from "@/composables/useGoTo";

const title = ref('title')
const router = useRouter()
const route = useRoute();
const settingStore = useSettingStore()
const {goHome} = useGoTo();
const isMobile = computed(() => settingStore.isMobile);

/*const props = defineProps({
  navBar: {
    type: Boolean,
    default: true
  },
  back: {
    type: Boolean,
    default: true
  },
  returnToIndex: {
    type: Boolean,
    default: false
  },
  sideBar: {
    type: Boolean,
    default: true
  },
  footer: {
    type: Boolean,
    default: true
  }
})*/

const defaultProps = {
  navBar: true,
  back: true,
  returnToIndex: false,
  sideBar: true,
  footer: true
};

const providedProps = inject('basicLayoutProps', defaultProps);
const props = reactive({ ...defaultProps, ...providedProps });

</script>
<style scoped
       lang="scss">

</style>
<style>
:root {
  --el-menu-hover-bg-color: #FFDF8D;
  --el-menu-active-color: #1C1B1F;
  --el-menu-text-color: #1C1B1F;
  --el-menu-bg-color: #ffffff;
  --el-menu-item-height: unset;
}
</style>
